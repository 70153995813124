import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "../lib/api";
import { LinkButton, SubmitButton } from "../components/Button";
import qs from "query-string";
import { useForm } from "react-hook-form";

export default function BookItemCreate() {
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm();

  const td_title = "pr-3 text-right text-sm lg:w-1/4 text-gray-700 font-bold";
  const td_body =
    "bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 my-0.5";

  const onSubmit = (data) => {
    axios
      .post(`/api/book/create`, qs.stringify(data), {
        headers: {
          Authorization: "bearer " + api.getToken(),
        },
      })
      .then((res) => {
        window.alert("등록되었습니다.");
        navigate("/list");
      })
      .catch((err) => window.alert(err));
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">도서 등록</div>
        <>
          <div className="mb-4 w-full lg:flex">
            <div className="border border-gray-300 rounded-md bg-white w-[200px] h-[260px] lg:mr-4 mx-auto mb-8 flex items-center justify-center text-gray-300">
              Cover Image
            </div>
            <div className="w-full rounded-md bg-gray-100 p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <table className="w-full">
                  <thead></thead>
                  <tbody className="">
                    <tr>
                      <td className={td_title}>도서ID</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("bookId")}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className={td_title}>도서명</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("title")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>제작일</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("prodDate")}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className={td_title}>학교급</td>
                      <td>
                        <select {...register("grade")} className={td_body}>
                          <option value="초">초</option>
                          <option value="중">중</option>
                          <option value="고">고</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>출판사</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("publisher")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>저자</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("creator")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>교육과정</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("curriculum")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>국검인</td>
                      <td>
                        <select {...register("permission")} className={td_body}>
                          <option value="국정">국정</option>
                          <option value="검정">검정</option>
                          <option value="인정">인정</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>교과용도</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("purpose")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>제작종류</td>
                      <td>
                        <select {...register("type")} className={td_body}>
                          <option value="brl">점자</option>
                          <option value="mag">확대</option>
                          <option value="spe">음성</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>발행일</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("pubDate")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={td_title}>ISBN</td>
                      <td>
                        <input
                          className={td_body}
                          type="text"
                          {...register("isbn")}
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="flex col-span-2 w-full justify-end pt-2 space-x-2">
                        <input
                          type="submit"
                          value="등록"
                          className="cursor-pointer hover:bg-blue-500 mt-4 ml-auto bg-sky-500 block w-40 p-2 rounded-lg text-white "
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </form>
            </div>
          </div>

          <LinkButton text={`목록으로`} address={`/list`} />
        </>
      </div>
    </>
  );
}
