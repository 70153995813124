import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import BookList from "./pages/BookList";
import HomeLayout from "./components/HomeLayout";
import RequireAuth from "./components/RequireAuth";
import BookItemEdit from "./pages/BookItemEdit";
import BookItemCreate from "./pages/BookItemCreate";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route
          path="/list"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemEdit />
              </RequireAuth>
            </HomeLayout>
          }
        />{" "}
        <Route
          path="/item/create"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemCreate />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
