import { useForm, SubmitHandler } from "react-hook-form";

export default function SearchBar({ setKeyword, setPage }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const onSubmit = (data) => {
    setKeyword(data);
    setPage(1);
  };

  return (
    <>
      <div className="border border-gray-200 rounded-md w-full p-4 mb-8 bg-gray-50 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="lg:flex">
            <div className="lg:w-1/2 lg:border-r lg:border-gray-300 lg:border-dotted lg:pr-6">
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  도서명
                </div>
                <input
                  {...register("title")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  저자
                </div>
                <input
                  {...register("creator")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  발행사
                </div>
                <input
                  {...register("publisher")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  발행일
                </div>
                <input
                  {...register("pubDate")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  Isbn
                </div>
                <input
                  {...register("isbn")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  교과용도
                </div>
                <input
                  {...register("purpose")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
            </div>
            <div className="lg:w-1/2 lg:pl-6 lg:pt-0 pt-8">
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  도서 ID
                </div>
                <input
                  {...register("bookId")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1 py-1.5">
                <div className="no-wrap text-sm w-1/6 items-center flex">
                  제작종류
                </div>
                <div className="space-x-4 flex">
                  <div>
                    <input
                      {...register("type")}
                      value=""
                      type="radio"
                      className="mr-1 "
                    ></input>
                    <label>전체</label>
                  </div>
                  <div>
                    <input
                      {...register("type")}
                      value="brl"
                      type="radio"
                      className="mr-1"
                    ></input>
                    <label>점자</label>
                  </div>
                  <div>
                    <input
                      {...register("type")}
                      value="mag"
                      type="radio"
                      className="mr-1 "
                    ></input>
                    <label>확대</label>
                  </div>
                  <div>
                    <input
                      {...register("type")}
                      value="spe"
                      type="radio"
                      className="mr-1 "
                    ></input>
                    <label>음성</label>
                  </div>
                </div>
              </div>

              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  교육과정
                </div>
                <input
                  {...register("curriculum")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="no-wrap mr-2 text-sm w-1/6 items-center flex">
                  제작일
                </div>
                <input
                  {...register("prodDate")}
                  className="bg-white w-full border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                ></input>
              </div>
              <div className="flex mb-1">
                <div className="w-1/2 flex ">
                  <div className="no-wrap mr-2 text-sm w-1/3 items-center flex">
                    학교급
                  </div>
                  <select
                    {...register("grade")}
                    className="bg-white w-2/3 border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm *:text-regular"
                  >
                    <option value="">전체</option>
                    <option value="초">초</option>
                    <option value="중">중</option>
                    <option value="고">고</option>
                  </select>
                </div>
                <div className="w-1/2 flex ml-8 lg:ml-14">
                  <div className="no-wrap mr-2 text-sm w-1/3 items-center flex">
                    국검인
                  </div>
                  <select
                    {...register("permission")}
                    className="bg-white w-2/3 border border-slate-200 rounded-md p-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm *:text-regular"
                  >
                    <option value="">전체</option>
                    <option value="국정">국정</option>
                    <option value="검정">검정</option>
                    <option value="인정">인정</option>
                  </select>
                </div>
              </div>
              <input
                type="submit"
                value="검색"
                className="cursor-pointer hover:bg-blue-500 mt-4 ml-auto bg-sky-500 block w-40 p-2 rounded-lg text-white "
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
