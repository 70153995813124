import { useRecoilValue } from "recoil";
import { tokenAtom } from "../lib/atom";
import { useContext, useEffect, useState } from "react";
import { login_address, server_address } from "../lib/api_address";
import { AuthContext } from "../lib/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

export default function LoginButton() {
  const token = useRecoilValue(tokenAtom);
  const [popup, setPopup] = useState();
  const { login, setUser } = useContext(AuthContext);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!location?.state?.from) {
      if (!!token) navigate(location.state.from);
    } else return;
  }, [token]);

  const handleLogin = (e) => {
    e.preventDefault();
    var addr;
    if (window.location.href.split("/")[2] === `localhost:3000`) {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://localhost:3000/auth`;
      // } else if (window.location.href.split("/")[2] === `dlib.itlo.org`) {
      //   addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=http://dlib.itlo.org/auth`;
    } else {
      addr = `${login_address}/?call=login&client_id=senseworldclient&response_type=code&redirect_uri=${server_address}/auth`;
    }

    let width = 400;
    let height = 600;
    let top = (window.innerHeight - height) / 2 + window.screenY;
    let left = (window.innerWidth - width) / 2 + window.screenX;

    let spec = "status=no, menubar=no, toolbar=no, resizable=no";
    spec += ", width=" + width + ", height=" + height;
    spec += ", top=" + top + ", left=" + left;

    const popupWin = window.open(addr, `_blank`, spec);
    setPopup(popupWin);
  };

  useEffect(() => {
    if (!popup) {
      return;
    }
    const oAuthCodeListener = (e) => {
      // 동일한 Origin 의 이벤트만 처리하도록 제한
      if (e.origin !== window.location.origin) {
        console.log(`origin error`);
        return;
      }
      const { code } = e.data;

      if (code) {
        // console.log(e.data);
        login(code)
          .then(() => {
            setUser();
          })
          .then(() => {
            popup?.close();
            setPopup(null);
          });
      }
    };
    window.addEventListener("message", oAuthCodeListener, false);
    return () => {
      window.removeEventListener("message", oAuthCodeListener);
      popup?.close();
      setPopup(null);
    };
  }, [popup]);

  return (
    <>
      {!token ? (
        <div
          className="border border-blue-500 bg-blue-500 sm:rounded-full py-4 sm:py-2 sm:my-2 sm:items-center hover:bg-blue-600 sm:text-center sm:flex px-6   cursor-pointer text-white font-bold"
          onClick={handleLogin}
        >
          로그인
        </div>
      ) : (
        <div
          className="sm:border sm:border-red-400 text-red-400 sm:rounded-full py-4 sm:py-2 sm:my-2 sm:items-center sm:text-center sm:flex px-6  sm:hover:bg-red-100 hover:bg-red-100 cursor-pointer font-bold"
          onClick={() => {
            auth.logout();
            navigate("/");
          }}
        >
          로그아웃
        </div>
      )}
    </>
  );
}
