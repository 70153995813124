import Header from "./Header";

export default function Home() {
  return (
    <>
      <Header />
      <div className="w-full h-full justify-center flex items-center"></div>
    </>
  );
}
