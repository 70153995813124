export function printUserId(id) {
  const num = id.indexOf("@");
  if (num < 0) return id;
  else return id.slice(0, num);
}

export function printTime(time, option) {
  const now = new Date();
  const day = new Date(time);
  const yyyy = day.getFullYear();
  const mm = ("0" + (day.getMonth() + 1)).slice(-2);
  const dd = ("0" + day.getDate()).slice(-2);
  const h = ("0" + day.getHours()).slice(-2);
  const m = ("0" + day.getMinutes()).slice(-2);
  const s = ("0" + day.getSeconds()).slice(-2);
  if (option === true) {
    return yyyy + "-" + mm + "-" + dd + " " + h + ":" + m + ":" + s;
  }

  if (now - day < 24 * 60 * 60 * 1000 && now.getDate() === day.getDate()) {
    return h + ":" + m;
  } else {
    return yyyy + "-" + mm + "-" + dd;
  }
}

export function printFileName(address) {
  while (address.indexOf("/") !== -1) {
    const slash = address.indexOf("/");
    address = address.slice(slash + 1);
  }
  // const num = name.indexOf("_");
  // return name.slice(num + 1);
  return address;
}

export function printImageToken(image) {
  return image.split("/")[0];
}

export function printAllDayArray(startDay, endDay) {
  const dateArray = [];
  let startDate = new Date(startDay);
  let endDate = new Date(endDay);

  while (startDate <= endDate) {
    dateArray.push(startDate.toISOString().split("T")[0]);
    startDate.setDate(startDate.getDate() + 1);
  }
  return dateArray;
}
