var login_address;
var api_address;
var server_address;
if (window.location.href.split(`/`)[2] === `contents.xvtech.com`) {
  login_address = "https://lgs.senwd.co.kr";
  api_address = "https://conapi.xvtech.com";
  server_address = "https://contents.xvtech.com";
} else {
  login_address = "https://dlgs.senwd.co.kr";
  api_address = "https://dapi.itlo.org";
  server_address = "https://dev.senwd.co.kr";
}

export { login_address, api_address, server_address };
