import { useNavigate } from "react-router-dom";
import LoginButton from "./components/LoginButton";

export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-gray-100">
        <div className="sm:w-3/4 sm:flex sm:mx-auto text-center">
          <div
            className="hover:bg-gray-200 p-4 cursor-pointer "
            onClick={() => navigate("/")}
          >
            📖
          </div>
          <div className="sm:flex ml-auto ">
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => navigate("/list")}
            >
              목록 검색
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => navigate("/item/create")}
            >
              신규 등록
            </div>
            <div className="">
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
